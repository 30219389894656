import React from "react"
import {GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql,Link } from "gatsby"



const IndustriesBanner = () => {
  const { aerospaceImg, footerPrefixImg } = useStaticQuery(
    graphql`
      query {       
        aerospaceImg: file(relativePath: {eq: "aerospace-ftrimg.jpg"}) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        footerPrefixImg: file(relativePath: {eq: "footer-prefix.jpg"}) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  )
    return(

        <div className="w-100 relative">
          <GatsbyImage image={getImage(aerospaceImg)} alt="footer banner" className="w-full md:block hidden" />
          <GatsbyImage image={getImage(footerPrefixImg)} alt="footer mobile banner" className="w-full md:hidden block" />
        {/* <StaticImage src="../../assets/images/aerospace-ftrimg.jpg" alt="" className="w-full md:block hidden" />
        <StaticImage src="../../assets/images/footer-prefix.jpg" alt="" className="w-full md:hidden block" /> */}
        <div className="max-w-7xl mx-auto">
          <div className="bg-white lg:py-12 sm:py-4 py-1 lg:px-16 px-4 lg:w-2/5 md:3/5 w-8/12 absolute lg:right-28 md:right-12 right-2 xl:bottom-16 md:bottom-8 bottom-5">
            <h3 className="font-bold sm:text-2xl sm:text-lg tex-sm">Couldn’t find what you were looking for?</h3>
            <p className="lg:pb-6 sm:pb-4 pb-0 sm:text-base text-sm sm:pt-4 pt-0">We also offer customized solutions based specifically on your use case</p>
            <Link to='/contact-us' className='capitalize font-semibold flex flex-row gap-8 items-center'>know more<svg className='w-14 h-8 fill-gray-600' clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m14.523 18.787s4.501-4.505 6.255-6.26c.146-.146.219-.338.219-.53s-.073-.383-.219-.53c-1.753-1.754-6.255-6.258-6.255-6.258-.144-.145-.334-.217-.524-.217-.193 0-.385.074-.532.221-.293.292-.295.766-.004 1.056l4.978 4.978h-14.692c-.414 0-.75.336-.75.75s.336.75.75.75h14.692l-4.979 4.979c-.289.289-.286.762.006 1.054.148.148.341.222.533.222.19 0 .378-.072.522-.215z" fillRule="nonzero" /></svg></Link>
          </div>
        </div>
      </div>

    )

}


 export default IndustriesBanner