import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Link } from 'gatsby'
const OtherIndustries = () => {
  const { canvasImage, IndustriesImage, IndustriesImage1 } = useStaticQuery(
    graphql`
      query {        
        canvasImage: file(relativePath: {eq: "canvas3.jpg"}) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        IndustriesImage: file(relativePath: {eq: "industries-img.png"}) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        IndustriesImage1: file(relativePath: {eq: "industries-img1.png"}) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `
  )
    return(
        <div className="w-full bg-gray-200 lg:py-20 py-10">
        <div className='max-w-7xl mx-auto px-4'>
          <div className='flex gap-4 md:flex-row flex-col gap-3'>
            <div className='md:w-1/4 w-full'>
              <h2 className='lg:text-3xl md:text-2xl sm:text-3xl text-xl font-medium text-gray-800 mb-5'>Other Industries</h2>
              <p className='lg:w-72 md:w-48 lg:text-xl sm:text-lg text-base mb-5'>See how Loopr's solutions are transforming a variety of industries</p>
            </div>

            <div className='md:w-3/4 w-full flex justify-between sm:flex-row flex-col lg:gap-16 lg:gap-10 gap-4'>
              <div className='sm:w-1/4  w-full'>
              <GatsbyImage image={getImage(canvasImage)} className='md:h-40 sm:h-32 h-100 sm:w-auto w-full'/>
                <div className='mt-2'>
                  <div className="index">
                    <strong className="text-gray-400 py-3">01</strong>
                  </div>
                  <div className='flex items-center justify-between'>
                    <h3 className='text-2xl font-medium text-gray-700 py-3'>
                      <strong>Retail</strong>
                    </h3>
                    <Link to="/industries/retail/">
                      <svg className='w-14 h-8 fill-gray-600' clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m14.523 18.787s4.501-4.505 6.255-6.26c.146-.146.219-.338.219-.53s-.073-.383-.219-.53c-1.753-1.754-6.255-6.258-6.255-6.258-.144-.145-.334-.217-.524-.217-.193 0-.385.074-.532.221-.293.292-.295.766-.004 1.056l4.978 4.978h-14.692c-.414 0-.75.336-.75.75s.336.75.75.75h14.692l-4.979 4.979c-.289.289-.286.762.006 1.054.148.148.341.222.533.222.19 0 .378-.072.522-.215z" fillRule="nonzero" /></svg>
                    </Link>
                  </div>
                  <p className='text-base font-medium text-gray-800'>
                    Create engaging and more personalized experiences
                    for your customers.
                  </p>
                </div>
              </div>
              <div className='sm:w-2/5 w-full'>
              <GatsbyImage image={getImage(IndustriesImage)} className='md:h-40 sm:h-32 h-100 sm:w-auto w-full'/>
                <div className='mt-2'>
                  <div className="index">
                    <strong className="text-gray-400 py-3">02</strong>
                  </div>
                  <div className='flex items-center justify-between'>
                    <h3 className='text-2xl font-medium text-gray-700 py-3'>
                      <strong>Manufacturing</strong>
                    </h3>
                    <Link to="/industries/manufacturing/">
                      <svg className='w-14 h-8 fill-gray-600' clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m14.523 18.787s4.501-4.505 6.255-6.26c.146-.146.219-.338.219-.53s-.073-.383-.219-.53c-1.753-1.754-6.255-6.258-6.255-6.258-.144-.145-.334-.217-.524-.217-.193 0-.385.074-.532.221-.293.292-.295.766-.004 1.056l4.978 4.978h-14.692c-.414 0-.75.336-.75.75s.336.75.75.75h14.692l-4.979 4.979c-.289.289-.286.762.006 1.054.148.148.341.222.533.222.19 0 .378-.072.522-.215z" fillRule="nonzero" /></svg>
                    </Link>
                  </div>
                  <p className='text-base font-medium text-gray-800'>
                    Make your Industrial processes more intelligent and reliable using Loopr's Computer Vision models.
                  </p>
                </div>
              </div>
              <div className='sm:w-1/4  w-full'>
              <GatsbyImage image={getImage(IndustriesImage1)} className='md:h-40 sm:h-32 h-100 sm:w-auto w-full'/>
                <div className='mt-2'>
                  <div className="index">
                    <strong className="text-gray-400 py-3">03</strong>
                  </div>
                  <div className='flex items-center justify-between'>
                    <h3 className='text-2xl font-medium text-gray-700 py-3'>
                      <strong>Automotive</strong>
                    </h3>
                    <Link to="/industries/automotive">
                      <svg className='w-14 h-8 fill-gray-600' clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m14.523 18.787s4.501-4.505 6.255-6.26c.146-.146.219-.338.219-.53s-.073-.383-.219-.53c-1.753-1.754-6.255-6.258-6.255-6.258-.144-.145-.334-.217-.524-.217-.193 0-.385.074-.532.221-.293.292-.295.766-.004 1.056l4.978 4.978h-14.692c-.414 0-.75.336-.75.75s.336.75.75.75h14.692l-4.979 4.979c-.289.289-.286.762.006 1.054.148.148.341.222.533.222.19 0 .378-.072.522-.215z" fillRule="nonzero" /></svg>
                    </Link>
                  </div>
                  <p className='text-base font-medium text-gray-800'>
                    Deep Learning solutions that go the extra mile to increase utility and safety.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
)}
export default OtherIndustries