import React from "react"
import { Link } from 'gatsby'

import {GatsbyImage, getImage } from "gatsby-plugin-image"


const IndustriesHeroSection = ({img, heading, content}) => {
    return(
        <div className="relative">
          <GatsbyImage image={getImage(img)} alt={heading && heading} className="sm:h-auto h-96" />    
        <div className="max-w-7xl mx-auto px-4">
          <div className="md:absolute relative lg:bottom-20 lg:bottom-5 inset-x-auto right-0 left-0 lg:w-4/12 md:w-6/12 w-full bg-white lg:px-16 sm:px-10 px-8 lg:py-12 py-6">
            <div className="w-full md:pb-1 pb-5">
              <h6 className="sm:text-xl text-base sm:mb-4 mb-0 text-gray-400 font-medium">Industries</h6>
              <h2 className="sm:text-3xl text-lg font-bold sm:mb-4 mb-0 w-48">{heading && heading}</h2>
              <p className="mb-5 sm:text-lg text-base w-full">{content && content}</p>

              <Link to="/contact-us" className=" border-2 font-bold border-blue-600 border-solid text-blue-600 px-10 py-3">
                Get a demo
              </Link>
            </div>
          </div>
        </div>
      </div>
)}
export default IndustriesHeroSection